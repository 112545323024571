const _ = require('lodash');

export default {
	computed: {
		sortingOpened() {
			return !!_.get(this.$store.state, `${this.module}.sorting.opened`);
		},

		sortingField: {
			get() {
				return this.$store.state[this.module].sorting.fields.find((f) => f.value === this.$store.state[this.module].sorting.selected.field);
			},

			set(obj) {
				this.$store.dispatch(`${this.module}/setSortingField`, obj.value);
			}
		},

		sortingDirection: {
			get() {
				return this.$store.state[this.module].sorting.directions.find((f) => f.value === this.$store.state[this.module].sorting.selected.direction);
			},

			set(obj) {
				this.$store.dispatch(`${this.module}/setSortingDirection`, obj.value);
			}
		},

		sortingMobileHidden() {
			const sortingEnabled = _.get(this.$store.state, `${this.module}.sorting.enabled`);
			if (sortingEnabled === undefined)
				return true;

			return !(_.get(this.$store.state, `${this.module}.sorting.opened`) && sortingEnabled);
		},
	},

	methods: {
		sortingToggle() {
			this.$store.dispatch(`${this.module}/setSortingOpened`);
		},

		setOrder(field) {
			//Se já está ordenado por este campo
			if (this.$store.state[this.module].sorting.selected.field === field) {
				//Muda somente direção
				this.sortingDirection = { value: this.$store.state[this.module].sorting.selected.direction === 'ascending' ? 'descending' : 'ascending' };
			} else {
				this.sortingField = {value: field};
			}
		},
	}
}
