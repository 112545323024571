const moment = require('moment');

export default {
	methods: {
		translateDate(date, format = 'DD/MM/YYYY HH:mm:ss') {
			return (date) ? moment(date).format(format) : '';
		},

		translateTrigger(trigger) {
			switch (trigger) {
				case 'delivery':
					return 'Entrega';
			}
		},

		translateActive(active) {
			return active ? 'Sim' : 'Não';
		},

		translateUnit(interval) {
			switch (interval) {
				case 'minute':
					return 'Minuto(s)';
				case 'day':
					return 'Dia(s)';
				case 'month':
					return 'Mes(es)';
			}
		},
	}
}
