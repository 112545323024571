const _ = require('lodash');

export default {
	computed: {
		filteringOpened() {
			return !!_.get(this.$store.state, `${this.module}.filtering.opened`);
		},

		filteringField: {
			get() {
				return this.$store.state[this.module].filtering.fields.find((f) => f.value === this.$store.state[this.module].filtering.selected.field);
			},

			set(obj) {
				this.$store.dispatch(`${this.module}/setFilteringField`, obj.value);
			}
		},

		filteringCriteria: {
			get() {
				return this.$store.state[this.module].filtering.criterias.find((f) => f.value === this.$store.state[this.module].filtering.selected.criteria);
			},

			set(obj) {
				this.$store.dispatch(`${this.module}/setFilteringCriteria`, obj.value);
			}
		},

		filteringKeyword: {
			get() {
				return this.$store.state[this.module].filtering.keyword;
			},

			set(keyword) {
				this.$store.dispatch(`${this.module}/setFilteringKeyword`, keyword);
			}
		},

		filteringMobileHidden() {
			const filteringEnabled = _.get(this.$store.state, `${this.module}.filtering.enabled`);
			if (filteringEnabled === undefined)
				return true;

			return !(_.get(this.$store.state, `${this.module}.filtering.opened`) && filteringEnabled);
		},
	},

	methods: {
		filteringToggle() {
			this.$store.dispatch(`${this.module}/setFilteringOpened`);
		},
	}
}
